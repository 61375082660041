<template>
    <section class="content">
        <div class="flex justify-content-center">
            <div class="overlay-wrapper">
                <div class="w-100 card">
                    <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-end mb-2">
                                <div class="row form-horizontal justify-content-end">
                                    <div class="col-auto col-xs-4">
                                    <h4>Periode Laporan</h4>
                                    </div>
                                    <div class="col-auto col-xs-4">
                                    <div class="input-group mb-3">
                                        <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                                        <div class="input-group-append">
                                        <div
                                            class="input-group-text"
                                            style="background-color: #fff">
                                            <span><span class="fa fa-calendar"></span></span>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-auto col-xs-4"></div>
                                    <div class="col-auto col-xs-4">
                                        <div class="btn-group" ref="toolbar">
                                        <button type="button" data-action="read" class="btn btn-default" v-on:click="showTable"><i class="fa fa-sync-alt"></i> Show Table</button>
                                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="loading" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2"></div>
                        <div class="table-responsive" :hidden="hideTable">
                            <table class="table table-hover" ref="tablebukubesar">
                            <thead>
                                <tr>
                                <th>NO. AKUN</th>
                                <th>NAMA AKUN</th>
                                <th>SALDO AWAL</th>
                                <th>DEBIT</th>
                                <th>KREDIT</th>
                                <th>SALDO</th>
                                </tr>
                            </thead>
                            <tbody @click="handleClick"></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Preview detail laporan -->
    <div class="modal" tabindex="-1" role="dialog" ref="formPreview" data-backdrop="static" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">JURNAL</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="overlay-wrapper">
                    <div class="w-100 card">
                        <div class="overlay" v-if="loadingContent2"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                        <span style="font-size: 15px; font-weight: 700; text-align: left">No. Akun : {{jurnal.coa_no}} / Nama Akun : {{jurnal.coa_name}}</span>
                        <span style="font-size: 15px; font-weight: 700; text-align: left">Periode : {{dt1}} - {{dt2}}</span>
                        <span style="font-size: 15px; font-weight: 700; text-align: left">Saldo Awal : Rp {{formatPrice(jurnal.saldoawal)}}</span>
                        <div class="card-body">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-end mb-2">
                                <div class="row form-horizontal justify-content-end">
                                    <div class="col-auto col-xs-4"></div>
                                    <div class="col-auto col-xs-4"></div>
                                    <div class="col-auto col-xs-4"></div>
                                    <div class="col-auto col-xs-4">
                                        <div class="btn-group" ref="toolbar2">
                                        <button type="button" data-action="read" class="btn btn-default"><i
                                                class="fa fa-sync-alt"></i></button>
                                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="loading2" v-on:click="downloadDetailXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2"></div>
                        <table style="width:100%" class="table table-hover" ref="tabledetail">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>NO. JURNAL</th>
                            <th>TANGGAL</th>
                            <th>REFERENSI</th>
                            <th>INVOICE ID</th>
                            <th>NO. AKUN</th>
                            <th>NAMA AKUN</th>
                            <th>CREATED BY</th>
                            <th>DEBIT</th>
                            <th>KREDIT</th>
                            <th>SALDO</th>
                            <th>NOTE</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                        <tfoot v-if="false">
                        <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        </tr>
                        </tfoot>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Tutup
            </button>
            </div>
        </div>
    </div>
    </div>
        </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "BukuBesar",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
                saldoawal: '',
            },
            filter: {},
            roles: "",
            role_id: "",
            coa_id: "",
            loading: false,
            loading2: false,
            loadingContent: false,
            loadingContent2: false,
            hideTable: true
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
        this.dateEnd = moment().format("YYYY-MM-DD");

        //set filter
        this.filter.dt1 = this.dateStart;
        this.filter.dt2 = this.dateEnd;
    },
    methods: {
        formatPrice(param){
            return formatCurrency(param);
        },
        onPeriode() {
            if (this.tableBukuBesar) {
                this.tableBukuBesar.api().ajax.reload();
            } else {
                // this.showTable();
            }
        },
        getDataItem(aData)
        {
            return {
                coa_id: aData.coa_id,
                coa_no: aData.coa_no,
                coa_name: aData.coa_name,
                saldoawal: aData.saldoawal,
            }
        },
        downloadXlsx: function (e) {
            const self = this;
            self.loading = true;
            self.loadingContent = true;
            var filter = JSON.parse(JSON.stringify(self.filter));
                filter.q = this.tableBukuBesar ? $("input[name=q]").val() : '';
                filter.category = this.tableBukuBesar ? $("select[name=category]").val() : 0;

            var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
            ).join("&");

            authFetch("/report/bukubesar/excell", {
                method: "POST",
                body: data,
            })
            .then((response) => {
                if (response.status === 201) {
                } else if (response.status === 400) {
                } else if (response.status === 500){
                    Swal.fire("Timeout!", ``, "error");
                    this.loading = false;
                    this.loadingContent = false;
                    return false;
                }

                return response.blob();
            })
            .then((blob) => {
            setTimeout(() => {
                self.loading = false;
            }, 1000);
            self.loadingContent = false;
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "data.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            });
        },
        downloadDetailXlsx: function (e) {
            const self = this;
            self.loading2 = true;
            self.loadingContent2 = true;
            var filter = self.filter;

            var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
            ).join("&");

            authFetch("/report/bukubesar/excell_detail", {
                method: "POST",
                body: data,
            }).then((response) => response.blob())
            .then((blob) => {
            setTimeout(() => {
                self.loading2 = false;
            }, 1000);
            self.loadingContent2 = false;
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "data.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            });
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
                this.dt1 = moment(this.dateStart).format("DD/MM/YYYY");
                this.dt2 = moment(this.dateEnd).format("DD/MM/YYYY");

                var nRow = $(e.target).closest("tr").get(0);
                var iRow = this.tableBukuBesar.fnGetPosition(nRow);
                var aData = this.tableBukuBesar.fnGetData(iRow);
                this.jurnal = this.getDataItem(aData);

                this.filter.saldo_awal = this.jurnal.saldoawal;
                this.filter.coa_id = e.target.dataset.id;

                if (this.tabledetail){
                    this.tabledetail.api().ajax.reload();
                } else {
                    this.detailTabel();
                }

                $(er.formPreview).modal("show");
                // return false;
            }
        },
        showTable: function () {
            if (this.tableBukuBesar) {
				this.tableBukuBesar.api().ajax.reload();
            } else {
                this.hideTable = false;
                const e = this.$refs;
                const self = this;
                this.tableBukuBesar = createTable(e.tablebukubesar, {
                    title: "",
                    roles: this.$route.params.roles,
                    selectedRowClass: "",
                    toolbarEl: e.toolbar,
                    serverSide: true,
                    frame: false,
                    processing: true,
                    language: {
                        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
                    lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
                    displayLength: 10,
                    lengthChange: true,
                    ajax: "/report/bukubesar",
                    paramData: function (d) {
                        var filter = JSON.parse(JSON.stringify(self.filter));
                        for (const key in filter) {
                            d[key] = filter[key] === false ? 0: filter[key];
                        }
                    },
                    columns: [
                        { data: "coa_no" },
                        { data: "coa_name" },
                        { 
                            data: "saldoawal", 
                            class:'text-right',
                            render: function(data, type, row, meta) {
                                return formatCurrency(data);
                            }
                        },
                        { 
                            data: "sumdebit", 
                            class:'text-right',
                            render: function(data, type, row, meta) {
                                return formatCurrency(data);
                            }
                        },
                        { 
                            data: "sumcredit", 
                            class:'text-right',
                            render: function(data, type, row, meta) {
                                return formatCurrency(data);
                            }
                        },
                        { 
                            data: "sumsaldo", 
                            class:'text-right',
                            render: function(data, type, row, meta) {
                                return formatCurrency(data);
                            }
                        },
                    ],
                    filterBy: [0, 1],
                    rowCallback: function (row, data) {
                        $("td:eq(0)", row).html(
                            '<a class="link link-role" data-id="' + data.coa_id + '" style="cursor:pointer;">' + data.coa_no + "</a>"
                        );
                    },
                    initComplete: function () {},
                });
            }
        },
        detailTabel: function () {
            const e = this.$refs;
            const self = this;
            this.tabledetail = createTable(e.tabledetail, {
                title: "",
                roles: ["read"],
                selectedRowClass: "",
                toolbarEl: e.toolbar2,
                serverSide: true,
                frame: false,
                processing: true,
                sort: false,
                responsive: true,
                scrollX: true,
                language: {
                    processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
                lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
                displayLength: 10,
                lengthChange: true,
                ajax: "/report/bukubesar/detail",
                paramData: function (d) {
                    var filter = JSON.parse(JSON.stringify(self.filter));
                    for (const key in filter) {
                        d[key] = filter[key] === false ? 0: filter[key];
                    }
                },
                columns: [
                    { data: "id", visible: false },
                    { data: "journal_no" },
                    { data: "journal_date",
                    render: function (data, type, row, meta) {
                        return !data ? '' : moment(data).format("DD/MM/YYYY");
                    }, },
                    { data: "referensi" },
                    { data: "invoice_code",
                    render: function(data, type, row, meta) {
                        return !data ? '' : data;
                    } },
                    { data: "coa_no" },
                    { data: "coa_name" },
                    { data: "display_name",
                    render: function(data, type, row, meta) {
                        return row.created_by == '' ? '' : row.created_by > 0 ? data : 'system';
                    } },
                    { 
                    data: "debit",
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return !data ? '' : formatCurrency(data);
                    }
                    },
                    { 
                    data: "credit", 
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return !data ? '' : formatCurrency(data);
                    }
                    },
                    { 
                    data: "saldo",
                    class:'text-right',
                    render: function(data, type, row, meta) {
                        return data != '...' ?  formatCurrency(data) : `<h5>${data}</h5>`;
                    }
                    },
                    { data: "note" }
                ],
                filterBy: [4],
                filter: true,
                rowCallback: function (row, data) {},
                footerCallback:function ( row, data, start, end, display ){
                }
            });
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment().add(-1, 'M').startOf("month"),
        endDate: moment(),
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onPeriode();
        });
    $('.loading-overlay').removeClass('show');
    },
}
</script>